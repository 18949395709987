.sort-module__SWg8Ga__overlay {
  --wcom-border-color: var(--lightningcss-light, var(--wcom-c-outline-variant-light)) var(--lightningcss-dark, var(--wcom-c-outline-variant-dark));
  z-index: 1;
  box-shadow: 0 0 10px 0 var(--wcom-c-blackAlpha10);
  border: 1px solid var(--wcom-border-color);
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  border-radius: 16px;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  display: none;
  position: absolute;
  top: 39px;
  right: 10px;
}

.sort-module__SWg8Ga__overlayOpen {
  display: flex;
}

.sort-module__SWg8Ga__headline {
  margin-bottom: 5px;
}

.sort-module__SWg8Ga__items {
  gap: 8px;
  display: flex;
}

.sort-module__SWg8Ga__item {
  width: 50%;
}

.sort-module__SWg8Ga__locateMeError {
  color: var(--lightningcss-light, var(--wcom-c-outline-light)) var(--lightningcss-dark, var(--wcom-c-outline-dark));
  margin-top: 8px;
  font-size: 12px;
}

